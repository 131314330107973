.gallery {
  background-color: var(--secondary);
  height: 16rem;
  width: 19rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: -10rem;
}

.gallery__imageContainer {
  height: 12.5rem;
  width: 17.5rem;
  background-color: var(--primary);
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.gallery__image {
  max-height: 12.5rem;
  max-width: 17.5rem;
}

.gallery__options{
  position: absolute;
  top: 0rem;
  right: 0rem;
  background-color:rgba(0, 0, 0, 0.5);
  height: 12.5rem;
  width: 10.5rem;
  display: none;
  align-items: flex-end;
  justify-content:space-evenly;
  flex-direction:column;
}

.gallery__optionsTop{
  position: absolute;
  top: 0rem;
  right: 0rem;
}

.gallery__optionsBottom{
  position: absolute;
  bottom: 0rem;
  right: 0rem;
}

.gallery__button{
  font-size:0.75rem;
  background-color: var(--accent);
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem;
}

.gallery__imageContainer:hover > .gallery__options{
  display: flex;
} 

.gallery__text {
  width: 17.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gallery__name {
  font-weight: 700;
}

.gallery__totalItems {
  color: var(--secondaryWhite);
  font-size: 0.95em;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
