.profile {
  height: calc(100vh - 7.5rem);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.profile::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.profile::-webkit-scrollbar-track {
  background: var(--secondary);
}
.profile::-webkit-scrollbar-thumb {
  background: var(--greyhighlight);
  border-radius: 0.3rem;
}

.noScrollBar {
  overflow-y: scroll;
  overflow-x: hidden;
}

.noScrollBar::-webkit-scrollbar {
  display: none;
  width: 8px;
  height: 8px;
}

.profile-head-section {
  display: flex;
  justify-content: space-between;
  margin-left: 3rem;
  margin-top: 2rem;
}
.profile-heading {
  font-size: large;
  font-family: Montserrat;
  font-weight: bolder;
}
.profile-edit {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.profile-edit-icon {
  padding: 10px;
  cursor: pointer;
}
.profile-edit-button {
  margin-right: 2rem;
  font-size: 16px;
}

.profile-grid {
  display: grid;
  grid-template-columns: 4fr 12fr 5fr;
  margin-top: 1.5rem;
  margin-left: 3rem;
}
.profile-image-with-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profile-img-back {
  height: 175px;
  width: 175px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  border-radius: 50%;
  background-color: var(--greyhighlight);
  cursor: pointer;
  overflow: hidden;
}

.profile-img {
  max-height: 175px;
  width: 175px;
}

.profile-img-back:hover > .profile-img-upload-button {
  display: flex;
}

.profile-img-upload-button {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 175px;
  width: 175px;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
}

.profile-img-button {
  padding: 0.25rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondaryWhite);
}

.profile-img-cancel-button {
  background: var(--greyhighlight);
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
}

.profile-img-save-button {
  background: var(--accent);
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
}

.profile-info {
  width: 20rem;
}

.profile__Name {
  display: flex;
  align-items: center;
}
.minimizeWidth {
  width: 8rem;
  margin-right: 2rem;
}
.profile-fullname-white {
  font-size: 24px;
  font-weight: bolder;
  font-family: Montserrat;
  color: var(--white);
  height: 2rem;
  background-color: var(--primary);
}
.profile-username-accent {
  font-size: small;
  color: var(--accent);
  font-weight: bold;
  margin-top: 0.3rem;
  height: 2rem;
  width: 10rem;
  font-family: Montserrat;
  background-color: var(--primary);
}
.profile-user-bio {
  font-size: smaller;
  color: var(--secondaryWhite);
  margin-top: 0.5rem;
  height: 4rem;
  width: 30rem;
  font-family: Montserrat;
  background-color: var(--primary);
  border: none;
}
.user-bio-margin {
  margin-top: 1rem;
}

.profile-contact {
  margin-top: 1.8rem;
}
.profile-contact-info {
  display: flex;
  align-items: center;
}
.profile-contact-info-text {
  font-size: smaller;
  font-family: Montserrat;
  background-color: var(--primary);
  border: none;
  color: var(--white);
}
.profile-contact-info-icon {
  padding: 10px;
  height: 15px;
}

.profile-grid-section2 {
  margin-left: 3rem;
  display: flex;
  justify-content: space-between;
}
.profile-payment-option {
  margin: 0.5rem 0rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.profile-payment-icon {
  height: 15px;
  padding: 0;
}
.profile-sub-heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.profile-text-grey {
  font-size: 16px;
  color: var(--secondaryWhite);
  margin-bottom: 1rem;
  height: 2rem;
  width: 18rem;
  background-color: var(--primary);
}
.profile-option-text-grey {
  font-size: 16px;
  color: var(--secondaryWhite);
  height: 2rem;
  width: 18rem;
  background-color: var(--primary);
}
.profile-edit-border {
  border: 1px solid var(--secondaryWhite);
  border-top: 0px solid var(--accent);
  border-right: 0px solid var(--accent);
  border-left: 0px solid var(--accent);
  font-family: Montserrat;
}
.profile-add-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.profile-add-button-sign {
  font-size: xx-large;
  font-family: Montserrat;
  color: var(--accent);
}
.profile-add-button-text {
  font-size: 16px;
  font-weight: medium;
  font-family: Montserrat;
  color: var(--white);
  padding-left: 5px;
  cursor: pointer;
}
.profile-button-group {
  display: flex;
  margin-right: 3rem;
  margin-top: 7rem;
}
.profile-cancel-button {
  height: max-content;
  width: max-content;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--inactive);
  color: var(--white);
  margin-right: 2rem;
  cursor: pointer;
}
.profile-save-button {
  width: 4rem;
  height: max-content;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  border-radius: 5px;
  background-color: var(--accent);
  color: var(--white);
  cursor: pointer;
}
.profile-edit-mode {
  visibility: hidden;
}

.hide {
  display: none;
}

.profile-change-Password {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.5rem;
}

.profile-flex-bottom {
  display: flex;
}

@media screen and (max-width: 1450px) {
  .profile-info {
    margin-left: 3rem;
  }
}

@media screen and (max-width: 1200px) {
  .profile-heading {
    font-size: 16px;
  }
  .profile-info {
    margin-left: 0rem;
  }
  .profile-edit-button {
    margin-right: 2rem;
    font-size: 14px;
  }
  .profile-grid {
    grid-template-columns: 1fr;
  }
  .profile-fullname-white {
    margin-top: 1rem;
  }
  .profile-grid-section2 {
    display: grid;
    grid-template-columns: 1fr;
  }
  .profile-button-group {
    margin-top: 4rem;
  }
}

.social__input {
  background-color: var(--primary);
  border: 1px solid var(--secondaryWhite);
  border-radius: 5px;
  color: var(--white);
}

@media screen and (max-width: 850px) {
  .profile-flex-bottom {
    flex-direction: column-reverse;
    justify-content: space-evenly;
  }

  .profile-add-button {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 790px) {
  .profile {
    height: calc(100vh - 12rem);
  }

  .profile-image-with-buttons {
    margin-left: -3rem;
  }

  .profile-img-back {
    height: 180px;
    width: 180px;
  }
  .profile-img {
    max-height: 180px;
    width: 180px;
  }
  .profile-info {
    width: 20rem;
  }
  .profile-fullname-white {
    font-size: 18px;
    margin-top: 1rem;
  }
  .profile-username-accent {
    font-size: smaller;
  }
  .profile-user-bio {
    font-size: 12px;
    width: 20rem;
  }
  .profile-sub-heading {
    font-size: 16px;
  }
  .profile-text-grey {
    font-size: 14px;
  }
  .profile-add-button-sign {
    font-size: x-large;
  }
  .profile-add-button-text {
    font-size: 16px;
  }
}
