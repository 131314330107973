.productShowcaseCard {
  background-color: var(--secondary);
  height: 19.5rem;
  width: 19rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
  margin-left: 1rem;
  text-decoration: none;
}

.productShowcaseCard__imageContainer {
  height: 11.5rem;
  width: 17.5rem;
  background-color: var(--primary);
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.productShowcaseCard__image {
  max-height: 11.5rem;
  max-width: 17.5rem;
}

.productShowcaseCard__imageDetails {
  position: absolute;
  top: 0rem;
  height: 11.5rem;
  width: 17.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(13, 13, 13, 0.5);
  display: none;
  transition: all 0.3s ease-in-out;
}

.productShowcaseCard__favouriteIcon {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  display: none;
  transition: all 0.3s ease-in-out;
}

.productShowcaseCard__shareIcon {
  position: absolute;
  top: 0.43rem;
  right: 3rem;
  display: none;
  transition: all 0.3s ease-in-out;
}

.productShowcaseCard__imageContainer:hover .productShowcaseCard__imageDetails {
  display: block;
}

.productShowcaseCard__imageContainer:hover .productShowcaseCard__shareIcon {
  display: block;
}

.productShowcaseCard__imageContainer:hover .productShowcaseCard__favouriteIcon {
  display: block;
}

.productShowcaseCard__description {
  height: 5.5rem;
  width: 17.5rem;
  color: var(--white);
  font-family: Montserrat, sans-serif;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 0.5rem;
}

.productShowcaseCard__rowOne {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 900;
}

.productShowcaseCard__price {
  color: var(--accent);
}

.productShowcaseCard__rowTwo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.7rem;
  color: var(--inactive);
}

.productShowcaseCard__dateAndViewsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productShowcaseCard__rowThree {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productShowcaseCard__people {
  font-size: 0.7rem;
}

.productShowcaseCard__owned {
  margin-top: 0.5rem;
}

.productShowcaseCard__name {
  text-decoration: underline;
  margin-left: 0.2rem;
}

.productShowcaseCard__button {
  cursor: pointer;
  background-color: var(--accent);
  height: 2rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 0.3rem;
  font-size: 0.8rem;
}

.productShowcaseCard__views {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.productShowcaseCard__viewsNumber {
  margin-left: 0.3rem;
}

@media screen and (max-width: 1920px) {
  .productShowcaseCard {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 1408px) {
  .productShowcaseCard {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 1380px) {
  .productShowcaseCard {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 600px) {
  .productShowcaseCard {
    margin-left: 0rem;
  }
}
