.addOrEditProductText {
  color: var(--white);
}

.addOrEditProductText__inputLabel {
  font-weight: bold;
  letter-spacing: 0.1rem;
  margin-bottom: 0.5rem;
}

.addOrEditProductText__inputText {
  background-color: var(--primary);
  outline: none;
  border: 1px solid var(--greyhighlight);
  color: var(--secondaryWhite);
  font-family: Montserrat, sans-serif;
  width: 80%;
  height: 1rem;
  padding: 0.5rem;
  border-radius: 0.3rem;
  letter-spacing: 0.05rem;
  margin-bottom: 1.5rem;
  color: var(--secondaryWhite);
}

.addOrEditProductText__title{
  background-color: var(--primary);
  outline: none;
  color: var(--white);
  font-family: Montserrat, sans-serif;
  width: 80%;
  height: 1rem;
 
  border-radius: 0.3rem;
  letter-spacing: 0.05rem;
  margin-bottom: 1.5rem;
  color: var(--secondaryWhite);
}

.extra__height {
  height: 5rem;
}

.addOrEditProductText__dropDown {
  width: 20rem;
  height: 2rem;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.3rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.addOrEditProductText__dropDownText {
  margin-left: 0.5rem;
}

.addOrEditProductText__dropDownImage {
  margin-right: 0.5rem;
}

.addOrEditProductText__checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.addOrEditProductText__checkboxCircle {
  height: 0.7rem;
  width: 0.7rem;
  background-color: var(--secondaryWhite);
  border-radius: 50%;
  margin-right: 0.5rem;
}

.addOrEditProductText__checkboxText {
  color: var(--secondaryWhite);
}

.addOrEditProductText__checkboxText:hover{
  color: var(--accent)
}

.addOrEditProductText__checkboxMarginBottom {
  margin-bottom: 1.5rem;
}

.accent__background{
  background:var(--accent)
}

.addOrEditProductText__smallInputGroup {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.addOrEditProductText__smallInput {
  background-color: var(--primary);
  outline: none;
  border: 1px solid var(--greyhighlight);
  color: var(--secondaryWhite);
  font-family: Montserrat, sans-serif;
  width: 15rem;
  height: 1rem;
  padding: 0.5rem;
  border-radius: 0.3rem;
  letter-spacing: 0.05rem;
  margin-right: 1rem;
  color: var(--secondaryWhite);
  justify-content: center;
}

.addOrEditProductText__buttonGroup {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0rem;
}

.addOrEditProductText__cancelButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--inactive);
  height: 2rem;
  width: 6rem;
  margin-right: 0.5rem;
}

.addOrEditProductText__submitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent);
  height: 2rem;
  width: 15rem;
  margin-right: 0.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
}


.addToGallery__dropDown {
  position: absolute;
  top: 10;
  right: 10;
  max-height: 100px;
  width: 20rem;
  border: 1px 1px 1px 1px solid var(--secondaryWhite);
  overflow-y: scroll;
  z-index: 12;
  background-color: var(--primary);
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
  border-radius: 1vh;
  margin-top: -1.5rem;
}

.addToGallery__dropDown::-webkit-scrollbar {
  width: 6px;
}
.addToGallery__dropDown::-webkit-scrollbar-track {
  background: #101522;
}
.addToGallery__dropDown::-webkit-scrollbar-thumb {
  background: var(--secondaryWhite);
  border-radius: 2.5vh;
}

.addToGallery__options {
  height: 2rem;
  background-color: var(--secondary);
  color: var(--white);
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  margin-top: 1vh;
  padding: 1vh 1vw 1vh 1vw;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 0.3rem;
}

.addToGallery__options:hover {
  background-color: var(--accent);
}

.rotate180 {
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media  screen and (max-width:600px){
  .addOrEditProductText__buttonGroup {
    margin-bottom: 2rem;
  }
}
