.userCard__container {
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 13rem;
  height: 14rem;
  border-radius: 0.3rem;
  cursor: pointer;
}

.userCard__imageContainer {
  height: 8.5rem;
  width: 8.5rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  margin-bottom: 0.3rem;
}

.userCard__image {
  height: 8.5rem;
  width: auto;
}

.userCard__textContainer {
  width: 90%;
  text-align: start;
}

.userCard__Name {
  margin: 0.3rem 0rem;
  font-size: 1.2rem;
}

.userCard__OwnedArts {
  color: var(--secondaryWhite);
}
