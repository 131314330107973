.red__border {
  border: 1.5px solid rgb(253, 120, 120);
}

.changePassword__error {
  color: rgb(253, 120, 120);
  margin-bottom: 1rem;
  margin-top: -1rem;
  font-size: 0.9rem;
}
