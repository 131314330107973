.messages {
  color: var(--white);
  display: flex;
}

.messages__right__empty {
  width: calc(100vw - 22rem);
  height: calc(100vh - 9rem);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: var(--secondary);
  word-spacing: 0.3rem;
}

@media screen and (max-width: 768px) {
  .no__display {
    display: none;
  }
}
