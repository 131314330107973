.secondaryNav {
  height: 3.5rem;
  width: 40vw;
  background-color: var(--white);
  margin-left: 3vw;
  background-color: var(--primary);
  display: flex;
  align-items: center;
}

.secondaryNav__option {
  margin-right: 1rem;
  color: var(--white);
  font-family: Montserrat, sans-serif;
  border: 1px solid var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  width: 11rem;
  border-radius: 2rem;
  font-size: 0.86rem;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.secondaryNav__option:hover {
  background-color: var(--accent);
}

.secondaryNav__imageContainer {
  margin-right: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accent__background {
  background-color: var(--accent);
  font-weight: bold;
  border-color: var(--accent);
}

@media screen and (max-width: 1420px) {
  .secondaryNav {
    height: 3rem;
    width: 50vw;
    margin-left: 3vw;
    background-color: var(--primary);
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
  .secondaryNav__optionText {
    display: none;
  }
  .secondaryNav__imageContainer {
    margin-right: 0rem;
  }
  .secondaryNav__image {
    height: 0.99rem;
    width: 0.99rem;
  }
  .secondaryNav {
    height: 3rem;
    width: 40vw;
    margin-left: 3vw;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 480px) {
  .secondaryNav__image {
    height: 0.99rem;
    width: 0.99rem;
  }
  .secondaryNav {
    height: 3rem;
    width: 40vw;
    margin-left: 3vw;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .secondaryNav__option {
    height: 1.8rem;
    width: 12rem;
    border-radius: 0.6rem;
    font-size: 0.86rem;
  }
}
