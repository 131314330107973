.sidebar__extended {
  display: none;
}

.sidebar {
  height: calc(100vh - 4rem);
  width: 18rem;
  background-color: var(--secondary);
  transition: all 0.3s;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 999;
}

.hide {
  display: none;
}

.sidebar::-webkit-scrollbar {
  width: 8px;
}
.sidebar::-webkit-scrollbar-track {
  background: var(--secondary);
}
.sidebar::-webkit-scrollbar-thumb {
  background: var(--greyhighlight);
  border-radius: 0.3rem;
}

.sidebar__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--white);
  font-family: Montserrat, sans-serif;
  height: 3.5rem;
  width: 18rem;
  transition: all 0.3s;
  text-decoration: none;
}

.sidebar__item:hover {
  background-color: var(--greyhighlight);
  cursor: pointer;
}

.sidebar__iconContainer {
  margin-right: 1rem;
  margin-left: 2.5rem;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar__name {
  margin-left: 0rem;
  transition: all 0.3s;
}

.sidebar__listItems {
  color: var(--white);
  font-family: Montserrat, sans-serif;
  margin-left: 5rem;
}

.sidebar__listItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--white);
  font-family: Montserrat, sans-serif;
  height: 3.5rem;
  padding-left: 1rem;
  transition: all 0.3s;
  cursor: pointer;
  text-decoration: none;
}

.sidebar__listItem:hover {
  background-color: var(--greyhighlight);
  cursor: pointer;
}

.sidebar__showMoreOption {
  display: flex;
  color: var(--inactive);
  align-items: center;
  justify-content: center;
  font-family: Montserrat, 'sans-serif';
  margin-left: -6rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.sidebar__showMoreOption:hover {
  cursor: pointer;
  color: var(--greyhighlight);
}

.sidebar__active {
  color: var(--accent);
  font-weight: bold;
}

.margin__right {
  padding-left: 1rem;
}

@media screen and (max-width: 1400px) {
  .sidebar {
    width: 15rem;
  }

  .sidebar__item {
    width: 15rem;
  }

  .sidebar__iconContainer {
    margin-left: 1rem;
  }

  .sidebar__listItems {
    margin-left: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .sidebar {
    margin-top: 0.5rem;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 3.5rem;
    position: fixed;
    bottom: 0rem;
    left: 0rem;
  }

  .sidebar__item {
    width: 16vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar__active {
    background-color: var(--secondary);
  }

  .sidebar__iconContainer {
    margin: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar__name {
    display: none;
  }

  .sidebar__listItems {
    display: none;
  }

  .sidebar__showMoreOption {
    display: none;
  }

  .sidebar__item:hover {
    background-color: var(--secondary);
  }

  .sidebar__listItem:hover {
    background-color: var(--secondary);
  }

  .sidebar__extended {
    color: var(--white);
    height: 2rem;
    width: 100vw;
    position: fixed;
    bottom: 3.5rem;
    left: 0rem;
    background-color: var(--secondary);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 0.75rem;
  }

  .sidebar__extendedlistItem {
    text-decoration: none;
    color: var(--secondaryWhite);
    border: 1px solid var(--secondaryWhite);
    padding: 0.1rem;
    border-radius: 0.3rem;
  }

  .sidebar__extendeditem {
    text-decoration: none;
    border: 1px solid var(--accent);
    padding: 0.1rem;
    border-radius: 0.3rem;
  }
}
