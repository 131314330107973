.sendingChat__opacity {
  opacity: 0.3;
  animation: beaming 1s infinite;
  -webkit-animation: beaming 1s infinite;
}

@keyframes beaming {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.5;
  }
}

.oneChat {
  margin-bottom: 1rem;
}

.oneChat__avatar {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.oneChat__sendersName {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.oneChat__singleText {
  color: var(--secondaryWhite);
  margin-top: 0.5rem;
}

.oneChat__date {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  text-align: center;
  font-size: 0.7rem;
  color: var(--secondaryWhite);
}

.oneChat__bottom {
  display: flex;
}

@media screen and (max-width: 1600px) {
  .oneChat__sendersName {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .oneChat__singleText {
    color: var(--secondaryWhite);
    margin-top: 0.45rem;
    font-size: 0.8;
  }
}

@media screen and (max-width: 1060px) {
}

@media screen and (max-width: 600px) {
  .oneChat__sendersName {
    font-size: 0.9rem;
  }
  .oneChat__singleText {
    font-size: 0.75;
  }
  .oneChat__avatar {
    height: 2rem;
    width: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .oneChat__sendersName {
    font-size: 0.9rem;
  }
  .oneChat__singleText {
    font-size: 0.75;
    margin-top: 0.3rem;
  }
  .oneChat__avatar {
    height: 2rem;
    width: 2rem;
  }
  .oneChat__date {
    margin-top: 2rem;
    width: 100%;
    height: 2rem;
    text-align: center;
    font-size: 0.6rem;
    color: var(--secondaryWhite);
  }
}
