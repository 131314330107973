.gallery__backButton {
  height: 3rem;
  display: flex;
  align-items: center;
  margin-left: 3vw;
  cursor: pointer;
  color: var(--secondaryWhite);
}

.gallery__EditButton {
  display: flex;
  align-items: center;
  margin-left: 3vw;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  cursor: pointer;
  border-radius: 0.3rem;
  color: var(--secondaryWhite);
}

.accentButton {
  background-color: var(--accent);
}

.gallery__detailsButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gallery__detailsTextFirstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -1rem;
}

.gallery__detailsTitle {
  font-size: 1.25rem;
  font-weight: 700;
  height: 3.5rem;
  letter-spacing: 0.1rem;
  display: flex;
  align-items: center;
}

.gallery__detailsEartTitle {
  font-size: 1.25rem;
  font-weight: 700;
  height: 3.5rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.1rem;
  margin-top: 1rem;
  margin-left: 3vw;
}

.gallery__detailsScroll {
  height: calc(100vh - 10rem);
  width: calc(100vw - 18rem);
  color: var(--white);
  background-color: var(--primary);

  font-family: Montserrat, sans-serif;

  overflow-y: scroll;
}

.gallery__detailsScroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.gallery__detailsScroll::-webkit-scrollbar-track {
  background: var(--secondary);
}
.gallery__detailsScroll::-webkit-scrollbar-thumb {
  background: var(--greyhighlight);
  border-radius: 0.3rem;
}

.gallery__detailsCardList {
  display: flex;
  flex-wrap: wrap;
  padding-left: 2.5vw;
}

.gallery__detailsText {
  margin-left: 3vw;
  width: 30rem;
  letter-spacing: 0.08rem;
  word-spacing: 0.2rem;
}

.gallery__detailsDescription {
  color: var(--secondaryWhite);
  width: 30rem;
  height: 6rem;
  background-color: var(--primary);
  font-family: Montserrat;
  font-size: 1rem;
  border: none;
  outline: none;
}

.gallery__detailsPicture {
  height: 12.5rem;
  width: 18rem;
  background-color: var(--secondaryWhite);
  margin-right: 1vw;
  border-radius: 0.3rem;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3vw;
}

.gallery__detailsImage {
  height: 12.5rem;
  max-width: 18rem;
}

.gallery__detailsPictureUpload {
  position: absolute;
  top: 0rem;
  left: 0rem;
  height: 12.5rem;
  width: 18rem;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

.gallery__detailsPicture:hover > .gallery__detailsPictureUpload {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery__detailsInfo {
  display: flex;
}

@media screen and (max-width: 1920px) {
  .gallery__detailsCardList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 22rem;
    row-gap: 1rem;
  }
}

@media screen and (max-width: 1520px) {
  .gallery__detailsCardList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 22rem;
  }
}

@media screen and (max-width: 1408px) {
  .gallery__detailsScroll {
    width: calc(100vw - 15rem);
  }

  .gallery__detailsCardList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 22rem;
  }
}

@media screen and (max-width: 1210px) {
  .gallery__detailsText {
    margin-left: 3vw;
    width: 22rem;
  }

  .gallery__detailsDescription {
    width: 22rem;
    height: 6rem;
  }

  .gallery__detailsCardList {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
}

@media screen and (max-width: 960px) {
  .gallery__detailsInfo {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
  .gallery__detailsText {
    margin-left: 0vw;
    width: 30rem;
  }

  .gallery__detailsDescription {
    width: 30rem;
    height: 6rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .gallery__detailsCardList {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 760px) {
  .gallery__detailsText {
    margin-left: 0vw;
    width: 25rem;
  }

  .gallery__detailsDescription {
    width: 25rem;
    height: 6rem;
  }
}

@media screen and (max-width: 700px) {
  .gallery__detailsText {
    font-size: 1rem;
    margin-left: 0vw;
    width: 25rem;
  }

  .gallery__detailsTitle {
    font-size: 1.1rem;
  }
  .gallery__detailsDescription {
    font-size: 0.9rem;
    width: 25rem;
    height: 6rem;
  }
}

@media screen and (max-width: 670px) {
  .gallery__detailsText {
    width: 20rem;
  }

  .gallery__detailsTitle {
    font-size: 1.1rem;
  }
  .gallery__detailsDescription {
    font-size: 0.9rem;
    width: 20rem;
    height: 5rem;
  }
}

@media screen and (max-width: 600px) {
  .gallery__detailsScroll {
    width: calc(100vw - 0rem);
  }
  .gallery__detailsCardList {
    padding-bottom: 3rem;
  }

  .gallery__detailsText {
    width: 90vw;
  }

  .gallery__detailsTitle {
    font-size: 1.4rem;
  }
  .gallery__detailsDescription {
    width: 90vw;
    height: 6rem;
    font-size: 1rem;
  }
}
