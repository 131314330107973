.navbar {
  font-family: 'Montserrat', sans-serif;
  height: 4rem;
  width: 100vw;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
}

.navbar__logo {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--accent);
  margin-left: 2.5rem;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.navbar__searchbarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.navbar__searchbar {
  background-color: var(--white);
  height: 1rem;
  width: 30vw;
  font-family: 'Montserrat', sans-serif;
  outline: none;
  font-weight: 500;
  padding: 0.5rem;
  border: none;
  border-radius: 0.3rem 0rem 0rem 0.3rem;
  transition: all 0.3s ease-in-out;
}

.navbar__searchIconContainer {
  height: 2rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--greyhighlight);
  border-radius: 0rem 0.3rem 0.3rem 0rem;
  transition: all 0.3s ease-in-out;
}

.navbar__authSection {
  margin-right: 2rem;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar__signup {
  background-color: var(--accent);
  height: 2rem;
  width: 5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  transition: all 0.3s ease-in-out;
  color: var(--white);
  cursor: pointer;
}

.no__display {
  transition: all 0.3s ease-in-out;
}

.show__display {
  transition: all 0.3s ease-in-out;
}

.navbar__flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar__icon {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  margin-right: -5vw;
  border-radius: 50%;
}

.navbar__shakeIcon {
  color: #5bc0eb;
  position: relative;

  margin-right: 3rem;
}

.shake {
  animation: shake 1s infinite;
  -webkit-animation: shake 1s infinite;
}

@keyframes shake {
  0% {
    transform: rotate(-8deg);
  }
  8% {
    transform: rotate(8deg);
  }
  16% {
    transform: rotate(-8deg);
  }
  24% {
    transform: rotate(8deg);
  }
  32% {
    transform: rotate(-8deg);
  }
  40% {
    transform: rotate(8deg);
  }
  48% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.navbar__notificationNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #de3c4b;
  color: #f5f9e9;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-size: 0.7rem;
  border-radius: 50%;
  height: 2.8vh;
  width: 2.8vh;
  position: absolute;
  top: -7px;
  right: -11px;
}

@media screen and (max-width: 1450px) {
  .navbar__icon {
    height: 1.5rem;
    width: 1.5rem;
  }
}

@media screen and (max-width: 720px) {
  .navbar__logo {
    margin-left: 0.5rem;
  }

  .navbar__searchIconContainer {
    width: 2.5rem;
  }

  .navbar__authSection {
    margin-right: 0.5rem;
  }

  .navbar__signup {
    width: 4rem;
    font-size: 0.8rem;
  }
  .navbar__icon {
    margin-top: 0.25rem;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 480px) {
  .navbar__searchbar {
    display: none;
  }
  .navbar__searchIconContainer {
    background-color: var(--secondary);
    margin-right: -35vw;
  }

  .show__display {
    display: inline;
    width: 50vw;
    margin-left: 20vw;
    transition: all 0.3s ease-in-out;
  }

  .no__display {
    display: none;
    transition: all 0.3s ease-in-out;
  }
  .navbar__shakeIcon {
    color: #5bc0eb;
    position: relative;

    margin-right: 1rem;
  }
}
