.myCollection {
  position: relative;
}

.myCollection__navWithAddButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  position: sticky;
  top: 7.5rem;
}

.myCollection__nav {
  margin-left: 3vw;
  display: flex;
  align-items: center;
  height: 3rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.myCollection__navItem {
  margin-right: 2rem;
  cursor: pointer;
  color: var(--secondaryWhite);
  transition: all 0.2s ease-in-out;
}

.myCollection__navItem:hover {
  color: var(--accent);
}

.active {
  color: var(--accent);
  font-weight: bold;
  transform: scale(1.15);
  transition: all 0.2s ease-in-out;
}

.myCollection__addButton {
  background-color: var(--accent);
  width: 8rem;
  height: 1.7rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 3vw;
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  color: var(--white);
  transition: all 0.3s ease-in-out;
}

.myCollection__addButton:hover {
  background-color: var(--secondary);
}

.myCollection__scroll {
  height: calc(100vh - 10.5rem);
}

.gallery__rowGap {
  height: 100%;
  width: 100%;
  color: var(--white);
  background-color: var(--primary);

  font-family: Montserrat, sans-serif;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden;
}

.gallery__rowGap::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.gallery__rowGap::-webkit-scrollbar-track {
  background: var(--secondary);
}
.gallery__rowGap::-webkit-scrollbar-thumb {
  background: var(--greyhighlight);
  border-radius: 0.3rem;
}

.myCollection__floatButton {
  display: none;
}

@media screen and (max-width: 1920px) {
  .gallery__rowGap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 18rem;
    row-gap: 1rem;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 1520px) {
  .gallery__rowGap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 18rem;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 1408px) {
  .gallery__rowGap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 18rem;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 999px) {
  .myCollection__addButton {
    display: none;
  }
  .myCollection__floatButton {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: var(--accent);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    bottom: 3.5rem;
    right: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .gallery__rowGap {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 18rem;
    justify-items: center;
    overflow-y: scroll;
    margin-left: -0.5rem;
    height: calc(100vh - 14rem);
  }

  .gallery__rowGap::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    margin-left: 5rem;
  }
  .gallery__rowGap::-webkit-scrollbar-track {
    background: var(--secondary);
  }
  .gallery__rowGap::-webkit-scrollbar-thumb {
    background: var(--greyhighlight);
    border-radius: 0.3rem;
  }

  .myCollection__scroll {
    height: calc(100vh - 14rem);
  }
  .myCollection__galleryFloat {
    bottom: 7.5rem;
  }

  .myCollectionGroupFloat {
    bottom: 11.5rem;
  }

  .myCollection__navItem {
    margin-right: 1rem;
    cursor: pointer;
  }
}
