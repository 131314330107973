.singleMessageOverview {
  display: grid;
  width: 95%;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  color: var(--white);
  margin-bottom: 1.5rem;
  padding: 0rem;
  cursor: pointer;
  text-decoration: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition: all 0.2s ease-in-out;
  border-radius: 0.3rem;
}

.active__MessageOverview {
  background-color: #4d4d4d;
}

.singleMessageOverview:hover {
  background-color: #4d4d4d;
}

.singleMessageOverview__imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #414141;
  height: 2rem;
  width: 2rem;
  margin-left: 2vw;
  border-radius: 50%;
}

.singleMessageOverview__message {
  color: var(--secondaryWhite);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 11rem;
}

.singleMessageOverview__nameAndActive {
  display: flex;
  align-items: center;
}

.singleMessageOverview__active {
  height: 0.7rem;
  width: 0.7rem;
  background-color: #adf18d;
  border-radius: 50%;
  margin-left: 1rem;
}

.singleMessageOverview__image {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

.singleMessageOverview__time {
  display: none;
}

.singleMessageOverview__messageContainer {
  margin-top: 0.5rem;

  display: grid;
  grid-template-columns: 8fr 2fr;
  grid-template-rows: 1fr;
}

.highlightedMessage {
  color: var(--accent);
  font-weight: bold;
}

.singleMessageOverview__messageCircle {
  height: 1rem;
  width: 1rem;
  padding: 0.15rem;
  background-color: var(--accent);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.8rem;
}

.loadingMessageOverview__imageContainer {
  height: 2rem;
  width: 2rem;
  background-color: #414141;
  border-radius: 50%;
  margin-left: 2vw;
}

.loadingMessageOverview__nameAndActive {
  height: 1.2rem;
  width: 5rem;
  background-color: #414141;
  border-radius: 0.5rem;
}

.loadingMessageOverview {
  height: 1.2rem;
  width: 10rem;
  background-color: #414141;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}

@media screen and (max-width: 1400px) {
  .singleMessageOverview {
    grid-template-columns: 1fr 6fr;
    justify-items: center;
  }

  .singleMessageOverview__messageContainer {
    place-items: center;
  }
}

@media screen and (max-width: 768px) {
  .singleMessageOverview__time {
    display: block;
    margin-right: 0rem;
    color: var(--secondaryWhite);
    font-size: 0.9rem;
  }
  .singleMessageOverview {
    width: 95%;
    grid-template-columns: 1fr 4fr 1fr;
  }
  .singleMessageOverview__message {
    width: 25rem;
  }
  .singleMessageOverview__imageContainer {
    margin-left: 0vw;
    height: 2.5rem;
    width: 2.5rem;
  }

  .singleMessageOverview__image {
    height: 2.5rem;
    width: 2.5rem;
  }
  .loadingMessageOverview__imageContainer {
    height: 2.5rem;
    width: 2.5rem;
    margin-left: 0vw;
  }
}

@media screen and (max-width: 670px) {
  .singleMessageOverview__message {
    width: 20rem;
  }
}

@media screen and (max-width: 570px) {
  .singleMessageOverview__message {
    width: 17rem;
  }
}

@media screen and (max-width: 510px) {
  .singleMessageOverview__message {
    width: 15rem;
  }
}

@media screen and (max-width: 460px) {
  .singleMessageOverview__message {
    width: 13rem;
  }
}

@media screen and (max-width: 420px) {
  .singleMessageOverview__message {
    width: 11rem;
  }
}
