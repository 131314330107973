.authentication {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(13, 13, 13, 0.7);
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100%;
  z-index: 1015;
}
.modal-container {
  background-color: var(--secondary);
  border: 20px;
  border-radius: 20px;
  border-color: var(--greyhighlight);
  width: 30rem;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.modal-bar-left {
  height: 0.5rem;
  width: 15rem;
  background-color: var(--accent);
  border-radius: 20px;
  z-index: 1200;
  position: absolute;
  top: 0rem;
  left: 0rem;
}
.modal-bar-right {
  height: 0.5rem;
  width: 15rem;
  background-color: var(--accent);
  border-radius: 20px;
  z-index: 1200;
  position: absolute;
  top: 0rem;
  right: 0rem;
}
.modal-paper {
  background-color: var(--secondary);
  border-radius: 10px;
  padding: 15px;
  transition: all 0.3s ease-in-out;
}

.auth-btn-grid {
  display: flex;
  width: 100%;
  height: 2rem;
  justify-content: space-around;
  padding: 10px;
}

.sign_up-btn1 {
  height: 2rem;
  width: 100%;
  font-family: Montserrat;
  font-size: 18px;
  color: var(--accent);
  font-weight: bold;
  background-color: var(--secondary);
  border: none;
  margin-top: 10px;
  cursor: pointer;
}
.sign_in-btn1 {
  height: 2rem;
  width: 100%;
  font-family: Montserrat;
  font-size: 18px;
  color: var(--greyhighlight);
  font-weight: bold;
  border: none;
  background-color: var(--secondary);
  margin-top: 10px;
  cursor: pointer;
}

.sign_up-btn2 {
  height: 2rem;
  width: 100%;
  font-family: Montserrat;
  font-size: 18px;
  color: var(--greyhighlight);
  font-weight: bold;
  background-color: var(--secondary);
  border: none;
  margin-top: 10px;
  cursor: pointer;
}
.sign_in-btn2 {
  height: 2rem;
  width: 100%;
  font-family: Montserrat;
  font-size: 18px;
  color: var(--accent);
  font-weight: bold;
  border: none;
  background-color: var(--secondary);
  margin-top: 10px;
  cursor: pointer;
}

.modal-heading {
  display: flex;
  font-size: 22px;
  font-family: Montserrat;
  padding: 30px;
  color: var(--accent);
  align-items: center;
  justify-content: center;
}
.modal-sub-heading {
  color: var(--white);
}

.input-field {
  background-color: var(--greyhighlight);
  border-radius: 10px;
  padding-left: 0.5rem;
  border: none;
  width: 94%;
  height: 6vh;
  color: var(--white);
  font-family: Montserrat, sans-serif;
}
.input-extra {
  width: 97%;
}
::placeholder {
  color: var(--inactive);
}

.modal-content {
  display: flex;
  justify-content: space-between;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  font-size: 16px;
}

.modal-checkbox-content {
  display: flex;
  width: 50%;
}

.modal-checkbox {
  color: var(--accent);
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-top: 18px;
  background-color: var(--accent);
}

.modal-checkbox-text {
  color: var(--white);
}

.modal-checkbox:checked {
  background-color: var(--accent);
}

.modal-content-text {
  width: 50%;
  color: var(--accent);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  right: 0;
  cursor: pointer;
}
.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.footer-text {
  color: var(--white);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
}

.footer-sub-text {
  color: var(--accent);
  cursor: pointer;
}

.confirm-btn {
  height: 2rem;
  width: 20%;
  font-family: Montserrat;
  font-size: 14px;
  color: var(--white);
  font-weight: bold;
  border: none;
  border-radius: 5px;
  background-color: var(--accent);

  cursor: pointer;
}

.form__error {
  color: var(--red);
  font-size: 11px;
  margin-bottom: -10px;
}

.red__border {
  border: 1.5px solid var(--red);
}

@media screen and (max-width: 1440px) {
  .modal-container {
    transform: scale(0.85);
  }
}

@media screen and (max-width: 600px) {
  .modal-heading {
    font-size: 14px;
  }
  .modal-container {
    width: 23rem;
  }

  .modal-bar-left {
    width: 12rem;
  }
  .modal-bar-right {
    width: 12rem;
  }
  .modal-paper {
    padding: 10px;
  }

  .input-field {
    height: 5vh;
    width: 90%;
    margin-left: 2.5vw;
    margin-bottom: -2rem;
  }

  .footer-text {
    font-size: 11px;
  }

  .confirm-btn {
    height: 2rem;
    font-size: 11px;
  }

  .modal-content {
    font-size: 14px;
  }

  .modal-checkbox {
    height: 10px;
    width: 10px;
  }

  .modal-checkbox-text {
    color: var(--white);
  }

  .modal-checkbox:checked {
    background-color: var(--accent);
  }

  .modal-content-text {
    font-size: 12px;
  }
  .sign_up-btn1 {
    font-size: 16px;
    height: 1.5rem;
  }
  .sign_in-btn1 {
    font-size: 16px;
    height: 1.5rem;
  }
  .sign_up-btn2 {
    font-size: 16px;
    height: 1.5rem;
  }
  .sign_in-btn2 {
    font-size: 16px;
    height: 1.5rem;
  }

  .form__error {
    color: red;
    font-size: 11px;
    margin-bottom: -22px;
    margin-top: 25px;
    margin-left: 13px;
  }
}
