.userName__modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(13, 13, 13, 0.7);
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
}
