.cardDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(13, 13, 13, 0.7);
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 2000;
}

.cardDetails__container {
  background-color: var(--secondary);
  color: var(--white);
  font-family: Montserrat, sans-serif;
  padding: 1rem;
  border-radius: 0.3rem;
}

.cardDetails__title {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.cardDetails__label {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: var(--white);
}

.cardDetails__inputBig {
  width: 20.8rem;
  height: 1.5rem;
  border: none;

  border-radius: 0.3rem;
  margin-bottom: 1rem;
  padding: 0rem 0.3rem;
  color: var(--secondaryWhite);
  outline: none;
}

.cardDetails__input {
  width: 4rem;
  height: 1.5rem;
  border: none;
  background-color: #3c3c3c;
  border-radius: 0.3rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0rem 0.3rem;
  color: var(--secondaryWhite);
  outline: none;
  text-align: center;
  margin-left: 1rem;
  color: var(--white);
}

.cardDetails__button {
  background-color: var(--accent);
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 0.3rem;
  font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
  .cardDetails__container {
    transform: scale(0.8);
  }
}
