/*Montserrat*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

html {
  --secondary: #1e1e1e;
  --primary: #111111;
  --white: #ffffff;
  --accent: #9d8df1;
  --greyhighlight: #4d4d4d;
  --inactive: #737983;
  --secondaryWhite: #c1c1c1;
  --red: rgb(253, 120, 120);
}

body {
  overflow: hidden;
}

div {
  transition: all 0.1s ease-in-out;
}

input {
  font-family: Montserrat;
  letter-spacing: 0.1rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--inactive) inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: var(--white) !important;
}

.App {
  font-family: Montserrat;
}

.app_bodyWithSideBar {
  display: flex;
}

.app__notAuthenticated {
  height: calc(100vh - 4.5rem);
  width: calc(100vw - 18rem);
  color: var(--white);
  background-color: var(--primary);
  transition: all 0.3s;
  font-family: Montserrat, sans-serif;
  position: relative;
}

.app__authenticated {
  height: calc(100vh - 7rem);
  width: calc(100vw - 18rem);
  color: var(--white);
  background-color: var(--primary);
  transition: all 0.3s;
  font-family: Montserrat, sans-serif;
  position: relative;
}

.home__scrollShade {
  pointer-events: none;
  position: absolute;
  bottom: -0.5rem;
  height: 2.5rem;
  width: 100%;
  background-image: linear-gradient(
    to top,
    rgba(17, 17, 17, 1),
    rgba(17, 17, 17, 0)
  );
}

.app__scroll {
  height: calc(100vh - 4rem);
  width: 100vw;
  overflow-y: scroll;
}

.app__scroll::-webkit-scrollbar {
  display: none;
}

.app__goback {
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 3vw;
  height: 3rem;
  cursor: pointer;
}

.app__bigImageComponent {
  height: calc(100vh - 10.5rem);
  width: 100vw;
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  padding-left: 0vw;
  /*background-color: #fff;*/

  align-items: center;
}

.app__bigImageContainer {
  height: calc(100vh - 12rem);
  width: 55vw;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.5rem;
  margin-left: 3vw;
}

.app__bigImage {
  max-width: 55vw;
  max-height: calc(100vh - 12rem);
}

.app__bigImageText {
  height: calc(100vh - 12rem);
  margin-left: 1vw;
  width: 38vw;
  /*background-color: var(--secondary);*/
  overflow-y: scroll;
  position: relative;
  border-radius: 0rem 0rem 0.3rem 0.3rem;
}

.app__bigImageText::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.app__bigImageText::-webkit-scrollbar-track {
  background: var(--primary);
}
.app__bigImageText::-webkit-scrollbar-thumb {
  background: var(--greyhighlight);
  border-radius: 0.3rem;
}

.illusionNav {
  display: none;
}

@media screen and (max-width: 1408px) {
  .app__notAuthenticated {
    width: calc(100vw - 15rem);
  }

  .app__authenticated {
    width: calc(100vw - 15rem);
  }
}

@media screen and (max-width: 720px) {
  .app__bigImageComponent {
    width: 100vw;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
  }
  .app__bigImageContainer {
    height: 15rem;
    width: 60vw;
    margin-left: 0vw;
    margin-bottom: 2.5rem;
  }

  .app__bigImage {
    max-height: 15rem;
    max-width: 60vw;
  }

  .app__bigImageText {
    max-height: calc(100vh - 12rem);
    margin-left: 1vw;
    width: 90vw;
    /*background-color: var(--secondary);*/
    overflow-y: scroll;
    position: relative;
  }
  /*.app__bigImageText::-webkit-scrollbar {
    display: none;
  }*/
}

@media screen and (max-width: 600px) {
  .app_bodyWithSideBar {
    flex-direction: column-reverse;
  }

  .app__notAuthenticated {
    width: 100vw;
    height: calc(100vh - 8rem);
  }
  .app__authenticated {
    width: 100vw;
    height: calc(100vh - 11rem);
  }

  .extra__divToHide {
    display: block;
    height: 8rem;
    width: 100vw;
  }

  .app__bigImageContainer {
    height: calc(70vh - 12rem);
    width: 80vw;
    background-color: var(--secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 0.5rem;
    margin-left: 3vw;
  }

  .app__bigImage {
    max-width: 80vw;
    max-height: calc(70vh - 12rem);
  }
}

@media screen and (max-width: 550px) {
  .illusionNav {
    margin-top: 3rem;
    width: 50vw;
    height: 1rem;
    display: flex;
  }
}

.pop__down {
  animation: pop-down 0.3s forwards;
  -webkit-animation: pop-down 0.3s forwards;
}

@-webkit-keyframes pop-down {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
}

@keyframes pop-down {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

.pop__up {
  animation: pop-up 0.3s forwards;
  -webkit-animation: pop-up 0.3s forwards;
}

@-webkit-keyframes pop-up {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@keyframes pop-up {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.leftToRight {
  animation: leftToRight 0.5s;
}

@keyframes leftToRight {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.rightToLeft {
  animation: rightToLeft 0.5s;
}

@keyframes rightToLeft {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.bottomToTop {
  animation: bottomToTop 0.5s;
}

@keyframes bottomToTop {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0%);
  }
}

.fadeIn {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mini__spinner {
  border: 0.5rem solid var(--secondary); /* Light grey */
  border-top: 0.5rem solid var(--accent); /* Blue */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
